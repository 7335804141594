<template>
<div>
    <skew-image src="img/aerial-view-of-road-between-green-grass-field-3635300.jpg"/>
    <container>
      <h1>Benvenuto,</h1>
    </container>
    <skrew-grey-container>
      <container>
        <h3>I tuoi progressi:</h3>
        <svg viewBox="0 0 64 64" class="pie" >
          <circle r="25%" cx="50%" cy="50%" v-bind:style="{'stroke-dasharray': `${questionLevelsProgressHistory[0]/479 * 100} 100`, 'stroke': 'green', 'stroke-dashoffset': 0}">
          </circle>
          <circle r="25%" cx="50%" cy="50%" v-bind:style="{'stroke-dasharray': `${questionLevelsProgressHistory[1]/479 * 100} 100`, 'stroke': 'rgba(255,125,33,1)', 'stroke-dashoffset': -questionLevelsProgressHistory[0]/479 * 100}">
          </circle>
          <circle r="25%" cx="50%" cy="50%" v-bind:style="{'stroke-dasharray': `${questionLevelsProgressHistory[2]/479 * 100} 100`, 'stroke': 'rgba(235, 34, 33,1)', 'stroke-dashoffset': -(questionLevelsProgressHistory[0]/479+ questionLevelsProgressHistory[1]/479) * 100}">
          </circle>
        </svg>
        <h6>🟢  Ben conosciuti ({{questionLevelsProgressHistory[0]}} su 479)</h6>
        <h6>🟠  Sbagliati qualche volta ({{questionLevelsProgressHistory[1]}} su 479)</h6>
        <h6>🔴  Sbagliati spesso ({{questionLevelsProgressHistory[2]}} su 479)</h6>
        <h6>⚪  Mai studiati ({{questionLevelsProgressHistory[3]}} su 479)</h6>
      </container>
    </skrew-grey-container>
    <f-button>Inizia il quiz</f-button>
  </div>
</template>

<style scoped>
h1 {
  font-size:3rem;
  line-height: 1.15;
  margin: 1.15rem 0px 1.15rem 0px;
}

h6 {
  margin:0px;
}

.pie {
  display: block;
  margin: auto;
  transform:rotate(-90deg);
  height: 25vh;
  background:#fff;
  border-radius: 50%;
  border: solid#0c0c0c 1px;
  margin-bottom: 1.15rem;
}

.pie circle {
  fill: none;
  stroke: #fff;
  stroke-width: 32;
}
</style>

<script>
import FButton from '@/components/FButton.vue';
import SkewImage from '@/components/SkewImage.vue';
import Container from '@/components/Container.vue';
import SkrewGreyContainer from '@/components/SkewGreyContainer.vue';

export default {
  name: 'Profile',
  components: {
    FButton, SkewImage, Container, SkrewGreyContainer,
  },
  computed: {
    questionLevelsProgressHistory() {
      return this.$root.questionLevelsProgressHistory;
    },
  },
};
</script>
