<template>
  <div>
    <slot></slot>
  </div>
</template>

<style scoped>
div {
  margin: 10px;
}
</style>

<script>
export default {
  name: 'Container',
};
</script>
