<template>
  <a>
    <slot></slot>
  </a>
</template>

<style scoped>
a {
    border-radius: 0;
    line-height: 2.5;
    padding: 0px 10px;
    font-size: 1.35rem;
    font-weight: bolder;
    text-align: center;
    border-radius: 5px;
    background-image:linear-gradient(90deg, rgba(235, 34, 33,1) 0%, rgba(255,125,33,1) 100%)  ;
    cursor: pointer;
    margin: 0;
    color:#fff;
    position: fixed;
    left: 10px;
    bottom: 10px;
}
</style>

<script>
export default {
  name: 'FButton',
  props: ['loading'],
};
</script>
