<template>
    <div class="grey">
      <slot></slot>
    </div>
</template>

<style scoped>
.grey {
  padding-top: 5px;
  padding-bottom: 5px;
  width:100vw;
  background-color:#e5e5e5;
  clip-path: polygon(0% 0%, 100% 10%, 100% 95%, 0% 100%);
}
</style>

<script>
export default {
  name: 'SkewImage',
};
</script>
