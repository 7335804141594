<template>
  <div class="wrapper">
    <div class="image" v-bind:style="{ 'background-image': 'url(' + src + ')' }">
      <img src="img/4.svg">
    </div>
  </div>
</template>

<style scoped>
.wrapper {
  filter: drop-shadow(1px 1px 3px rgba(50, 50, 0, 0.5));
}
.image {
  width:100vw;
  height:20vh;
  background-size: cover;
  background-repeat:no-repeat;
  clip-path: polygon(0 0, 100% 0%, 100% 75%, 0 100%);
}

img {
  height: 5vh;
  margin: 10px;
}
</style>

<script>
export default {
  name: 'SkewImage',
  props: ['src'],
};
</script>
